import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpUtilsService } from '../../../core/services/http-utils.service';
import { AppSettings } from './../../../app.config';
import { map } from 'rxjs/operators';
import { TOKEN_AUTH_PASSWORD, TOKEN_AUTH_USERNAME, InterceptorSkipHeader } from './../../../core/auth/auth.constant';

const API_URL: string = AppSettings.PUBLICAPI_ENDPOINT + 'user';
@Injectable({
    providedIn: 'root',
})
export class PublicUserService {
    public urls: string;
    constructor(
        private http: HttpClient, private httpUtils: HttpUtilsService) {}


    private getPublicHeaders(): HttpHeaders {
        let httpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		httpHeaders = httpHeaders.set(InterceptorSkipHeader, '');
        httpHeaders = httpHeaders.append('Authorization', 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD));
        return httpHeaders;
    }

    public getByEmail(email: string) {
        const url = API_URL + '/getByEmail';
        let params = new HttpParams();
        params = params.append('email', email.toString());
        return this.http.get(url, { params: params, headers: this.getPublicHeaders()}).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public getByPhoneandTC(phone: number, identity: number) {
        const url = API_URL + '/getByPhone';
        let params = new HttpParams();
        params = params.append('phone', phone.toString());
        params = params.append('identity', identity.toString());
        return this.http.get(url, { params: params, headers: this.getPublicHeaders()}).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public verifySmsToken(userId: number, smsToken: string) {
        const url = API_URL + '/verifySmsToken';
        let params = new HttpParams();
        params = params.append('userId', userId.toString());
        params = params.append('smsToken', smsToken.toString());
        return this.http.get(url, { params: params, headers: this.getPublicHeaders()}).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public verifyEmailToken(userId: number, emailToken: string) {
        const url = API_URL + '/verifyEmailToken';
        let params = new HttpParams();
        params = params.append('userId', userId.toString());
        params = params.append('emailToken', emailToken.toString());
        return this.http.get(url, { params: params, headers: this.getPublicHeaders()}).pipe(
            map((result: any) => {
                return result;
            })
        );
    }

    public updatePassword(userId: number, password: string) {
        const url = API_URL + '/updatePassword';
        let params = new HttpParams();
        params = params.append('userId', userId.toString());
        params = params.append('password', password.toString());
        return this.http.put(url, null, { params: params, headers: this.getPublicHeaders()}).pipe(
            map((result: any) => {
                return result;
            })
        );

    }

    public deleteAccount(username: string, password: string) {
        const url = API_URL + '/deleteAccount';
        let params = new HttpParams();
        params = params.append('username', username);
        params = params.append('password', password);
        return this.http.put(url, null, { params: params, headers: this.getPublicHeaders()}).pipe(
            map((result: any) => {
                return result;
            })
        );

    }

}
